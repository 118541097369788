/* Full screen loading */
.overlayWrapper {
  position: relative;
  min-height: 100vh;
  margin-bottom: 100px;
}

/* Search input style */
.searchBox {
  margin: 20px;
  border: 1px solid #1C6EA4;
  display: inline-block;
  border-radius: 20px;
  padding: 30px;
  background-color: #e0ecff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}

/* Search results style */
.tablestyle {
  list-style:none;
  padding: 1px 1px;
}
.tablestyle img {
  vertical-align: middle;
  border-radius: 10px;
}
.tablestyle li {
  list-style:none;
  padding: 5px 5px;
  border: 1px solid #1C6EA4;
  margin-top: 10px;
  text-align: center;
  box-shadow: 2px 2px #888888;
  border-radius: 20px;
}
.tablestyle li:hover{
  color: white;
  background-color: rgb(60, 129, 226);
}
.tablestyle li:active{
  color: white;
  background-color: #044599;
}
.tablestyle a {
  color: purple;
  text-decoration: none;
}
.tablestyle a:link {
  color: black;
}
.tablestyle a:hover {
  color: blue;
}
