/* Full screen loading */
.overlayWrapper {
  position: relative;
  min-height: 100vh;
  margin-bottom: 100px;
}
.inputBox {
  border: 1px solid #1C6EA4;
  border-radius: 20px;
  padding: 10px;
  width: 400px;
  align-items: baseline;
  justify-content: center;
  background-color: #e0ecff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}
.viewBox {
  margin: 20px;
  border: 1px solid #1C6EA4;
  display: inline-block;
  border-radius: 20px;
  padding: 10px;
  background-color: #e0ecff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}
.customFileUpload {
  display: inline-block;
  line-height: 2.2em;
  padding: 0 0.62em;
  border: 1px solid #666;
  border-radius: 0.25em;
  background-image: linear-gradient( to bottom, #fff, #ccc );
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  font-family: arial, sans-serif;
  font-size: 0.8em;
}
.tablestyle {
  list-style:none;
  padding: 1px 1px;
  display: flexbox;
  text-align: left;
}
.tablestyle li {
  list-style: none;
  padding: 5px 15px;
  border: 1px solid #1C6EA4;
  margin-top: 10px;
  box-shadow: 2px 2px #888888;
  border-radius: 5px;
  display: inline-block;
}

/* Result style */
.resultContainer {
  margin: 5px;
  border: 1px solid #1C6EA4;
  display: inline-block;
  border-radius: 20px;
  padding: 30px;
  background-color: #89b6f9;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  max-width: 900px;
}
.resultContainer li {
  text-align: center;
  list-style: none;
  margin: 5px;
}
