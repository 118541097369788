@media (min-width: 900px) {
  .mainContainer { grid-template-columns: repeat(3, 1fr); }
}

/* Main Container  style */
.mainContainer {
  max-width: 100%;
  display: grid;
  margin: 0 auto;
  gap: 1rem;
  text-align: center;
}

/* Container Items style */
.hiddenContainerItems {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  display: grid;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  height: 300px;
  align-items: baseline;
}
/* Container Items style */
.containerMainItems {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #1C6EA4;
  display: grid;
  border-radius: 20px;
  padding: 20px;
  justify-content: center;
  align-items: baseline;
  background-color: #e0ecff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}
/* Container Items style */
.containerItems {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #1C6EA4;
  display: grid;
  border-radius: 20px;
  padding: 20px;
  justify-content: center;
  align-items: baseline;
  background-color: #e0ecff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}
/* Search results style */
.tablestyle {
  margin-top: -10px;
  list-style: none;
  padding: 1px 1px;
  display: inline-block;
  vertical-align: top;
}
.tablestyle img {
  vertical-align: middle;
  border-radius: 10px;
}
.tablestyle li {
  list-style:none;
  padding: 5px 15px;
  margin-top: 10px;
  text-align: center;
  border-radius: 20px;
}
